<template>
  <form @submit.prevent="handleUpdate" class="flex flex-col gap-10 items-start justify-center relative">
    <div class="flex flex-col gap-10 items-center justify-center self-stretch shrink-0 relative">
      <div class="text-[#1b224b] text-left relative self-stretch flex items-center justify-start"
        style="font: 500 16px/120% 'Albert Sans', sans-serif">
        Add Your profile picture
      </div>
      <div class="rounded-[300px] shrink-0 w-[214px] h-[214px] relative overflow-hidden" style="
          background: linear-gradient(to left, #fef1f4, #fef1f4),
            linear-gradient(to left, #ffffff, #ffffff);
        ">
        <img v-if="fileUrl || profile_picture" :src="fileUrl ? fileUrl : details?.profile_picture" class="mt- mb-4 h-full w-full object-cover mx-auto lg:mx-0" alt="profilePicture" />
        <div
          class="bg-[#ffffff] rounded-[20px] p-2.5 flex flex-col gap-2.5 items-start justify-start absolute left-[calc(50%_-_68px)] top-[calc(50%_-_18px)]"
          style="box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06)">
          <div class="flex flex-row gap-2 items-start justify-start shrink-0 relative">
            <div class="shrink-0 w-4 h-4 relative">
              <div class="absolute" style="inset: 0">
                <svg class="absolute left-0 top-0 overflow-visible" style="" width="16" height="16" viewBox="0 0 16 16"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.50613 14.6667H11.4928C13.3328 14.6667 14.0661 13.54 14.1528 12.1667L14.4995 6.66004C14.5928 5.22004 13.4461 4.00004 11.9995 4.00004C11.5928 4.00004 11.2195 3.76671 11.0328 3.40671L10.5528 2.44004C10.2461 1.83337 9.44613 1.33337 8.76613 1.33337H7.23946C6.55279 1.33337 5.75279 1.83337 5.44613 2.44004L4.96613 3.40671C4.77946 3.76671 4.40613 4.00004 3.99946 4.00004C2.55279 4.00004 1.40613 5.22004 1.49946 6.66004L1.84613 12.1667C1.92613 13.54 2.66613 14.6667 4.50613 14.6667Z"
                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7 5.33337H9H7Z" fill="#828282" />
                  <path d="M7 5.33337H9" stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M8.00065 12C9.19398 12 10.1673 11.0266 10.1673 9.83329C10.1673 8.63996 9.19398 7.66663 8.00065 7.66663C6.80732 7.66663 5.83398 8.63996 5.83398 9.83329C5.83398 11.0266 6.80732 12 8.00065 12Z"
                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <div class="text-gray-1 text-left relative" style="font: 400 12px/16px 'Albert Sans', sans-serif">

              <label for="dropzone-file">
                <div class=" cursor-pointer">
                  <span class="font-normal text-sm">Add Profile Photo</span>
                </div>
                <input ref="selectedFile" id="dropzone-file" type="file" class="hidden" accept=".jpg,.jpeg,.png,.svg"
                  @change="previewImage" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full gap-3.5 items-start justify-center shrink-0 relative overflow-hidden">
      <div class="text-[#1b224b] text-left relative flex items-center justify-start"
        style="font: 500 16px/120% 'Albert Sans', sans-serif">
        Country
      </div>
      <div class="shrink-0 w-full h-14 static">
        <select required :selected="details.country_id" v-model="details.country_id" id="country_id" @change="getStates" class="rounded-[10px] border-solid border-[#f7edf5] border-2 w-full h-14 absolute left-0 top-[33px]">
          <option>===Select===</option>
          <option v-for="(country,idx) in countries" :key="idx" :value=country.id>{{ country.value }}</option>
        </select>
      </div>
    </div>
    <div class="flex flex-col w-full gap-3 items-start justify-start shrink-0 relative">
      <div class="text-[#1b224b] text-left relative flex items-center justify-start"
        style="font: 500 16px/120% 'Albert Sans', sans-serif">
        State
      </div>
      <div class="shrink-0 w-full h-14 static">
        <select required v-model="details.state_id" :selected="details.state_id" id="state_id" @change="updateState" class="rounded-[10px] border-solid border-[#f7edf5] border-2 w-full h-14 absolute left-0 top-[33px]">
          <option>===Select===</option>
          <option v-for="(state, idx) in states" :key="idx" :value=state.id>{{ state.value }}</option>
        </select>
      </div>
    </div>
    <div class="flex flex-col w-full gap-3 items-start justify-start shrink-0 relative">
      <div class="text-[#1b224b] text-left relative flex items-center justify-start"
        style="font: 500 16px/120% 'Albert Sans', sans-serif">
        Years of Experience
      </div>
      <div class="shrink-0 w-full h-14 static">
        <input required v-model="details.years_of_experiences" type="number" min="1" class="rounded-[10px] border-solid border-[#f7edf5] border-2 w-full h-14 absolute left-0 top-[33px]" />
      </div>
    </div>
    <div class="flex flex-row gap-3.5 items-start justify-start shrink-0 relative" v-if="props.task == 'onboard'">
      <input v-model="details.checked" id="check" type="checkbox" name="check" class="md:mr-3 rounded-[3px] border-solid border-[rgba(0,0,0,0.20)] border shrink-0 w-7 h-7 relative" />
      <div class="text-[#1b224b] text-left relative w-full flex items-center justify-start" style="
          font: 400 14px/140% 'Albert Sans', sans-serif;
          opacity: 0.699999988079071;
        ">
        I verify that I am an authorized representative of this organization and
        have the right to act on its behalf in the creation and management of
        this page. The organization and I agree to the additional terms for
        Companies
      </div>
    </div>
    <div class="w-full py-3 px-4 text-left mb-6">
      <button   type="submit"
        class="bg-brand-black sm:px-6 md:px-20 md:w-fit px-20 text-white py-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed">
        {{ loading ? "Loading..." : button_action }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';

const store = useStore();

const details = ref({
  profile_picture: "",
  country_id: "",
  state_id: "",
  work_experiences: [],
  years_of_experiences: 0,
  checked: false,
});
const pageLoaded = ref(false);
const disabled = ref(false);
const loading = ref(false);
const profile_picture = ref(null);
const file = ref(null);
const fileUrl = ref(null);

const props = defineProps({
  task: { type: String, default: 'onboard' },
});

const emits = defineEmits(['changeTab','enableSubmit']);

const button_action = computed(() => props.task === 'onboard' ? 'Submit' : 'Save');

const userForm = computed(async() =>  await store.getters['global/getForm']);

const userDetails = computed(async () => await store.getters['auth/userDetails']);

const countries = computed(() => {
  return store.getters["global/getCountries"];
});

const states = computed(() => store.getters["global/getStates"]);

const getStates = async () => {
  const country_id = Number(document.getElementById('country_id').value);
  if (Number.isNaN(country_id)) {
    store.commit('global/setStates', []);
    return;
  }
  details.value.country_id = country_id
  const country = store.getters['global/getCountry'](country_id);
  await store.dispatch("global/getStates", country?.key);
};

const updateState = async () => {
  const state_id = Number(document.getElementById('state_id').value);
  if (Number.isNaN(state_id)) {
    details.value.state_id = ''
    return;
  }
  details.value.state_id = state_id
};

const checkDisability = computed(() => {
  if (Number(details.value.state_id) > 0 &&
    details.value.years_of_experiences > 0) {
    //emits('enableSubmit', true)
    disabled.value = true
    return true
  } else {
    disabled.value = true
    //emits('enableSubmit', false)
    return true;
  }
});

const previewImage = (e) => {
  if (e?.target.files) {
    let reader = new FileReader();
    reader.onload = (e) => {
      if (!e) return;
      fileUrl.value = e.target.result;
    };
    if (e.target.files[0].size > 5000000) {
      alert('file too big', 'error');
      return;
    }
    reader.readAsDataURL(e.target.files[0]);
    file.value = e.target.files[0];
  }
};

async function updateAvatar() {
  try {
    const data = new FormData();
    if (file.value) {
      data.append('profile_picture', file.value);
    }
    await store.dispatch('auth/updateUserAvatar', data);
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}

async function handleUpdate() {
  try {
    disabled.value = true;
    loading.value = true;

    updateAvatar();
    if (props.task == 'onboard') {
      await store.commit('global/setForm', { ...userForm.value, ...details.value });
      emits('changeTab');
    } else {
      delete details.value.profile_picture
      const { message } = await store.dispatch('auth/updateEmployeeProfile', details.value);
      alert(message, 'success');
      store.dispatch('auth/getUser', { forceReload: true });
    }
    disabled.value = false;
    loading.value = false;
  } catch (error) {
    disabled.value = false;
    loading.value = false;
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}

onMounted(async () => {
  if (props.task === 'onboard') {
    document.getElementById('check')?.setAttribute('required', true);
  }
  const user = await store.getters['auth/userDetails'];
  const formData = { ...user.employer_user, ...userForm.value }
  details.value = {
    profile_picture: formData.profile_picture,
    country_id: formData.country_id,
    state_id: formData.state_id,
    work_experiences: [],
    years_of_experiences: formData.years_of_experiences,
  };  
  if (formData.state_id) {
    document.getElementById('country_id').value = formData.country_id;
    document.getElementById('state_id').value = formData.state_id;
  }
  profile_picture.value = fileUrl.value ? fileUrl.value : formData.profile_picture;
  if (details.value.country_id) {
    getStates(details.value.country_id);
  }
  pageLoaded.value = true;
});

</script>

<style scoped></style>

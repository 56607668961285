<template>
  <form @submit.prevent="handleUpdate" class="mt-6">
    <div class="col-span-6 sm:col-span-3 mb-6 p-4 lg:p-0">
      <label for="work-type" class="block text-base font-semibold text-[#090E11]/70">Company Description</label>
      <textarea
        required
        id="description"
        v-model="details.employer.bio"
        rows="5"
        cols="33"
        placeholder="Start typing..."
        class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
      >
      Start typing...
      </textarea>
      <div class="">
        <p class="text-right text-[#1B224B] opacity-60 font-normal text-sm mt-1">Minimum of 80 characters</p>
      </div>
    </div>
    <div class="col-span-6 sm:col-span-3 mb-6 p-4 lg:p-0">
      <label for="work-type" class="block text-base font-semibold text-[#090E11]/70">Year Founded</label>
      <!-- <Datepicker v-model="picked" year-picker class="" /> -->
      <select
        required
        v-model="details.employer.founded_at"
        id="role-type"
        name="role-type"
        autocomplete="role-type"
        class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
      >
        <option disabled value="">Select One</option>
        <option class="" v-for="year in years" :key="year" :value="`${year}-01-01`">
          {{ year }}
        </option>
      </select>
    </div>
    <div class="col-span-6 sm:col-span-3 mb-6 p-4 lg:p-0">
      <label for="work-type" class="block text-base font-semibold font-GTWalsheimPro text-[#090E11]/70">Industry</label>
      <span class="text-[#090E11]/70 font-normal text-sm">Select one that applies to you</span>
      <p></p>
      <select
        required
        @change="setCategoryDetails"
        v-model="selectedCategory"
        id="role-type"
        name="role-type"
        autocomplete="role-type"
        class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
      >
        <option disabled value="">Select One</option>
        <option class="" v-for="item in companyCategories" :key="item.key" :value="item">
          {{ item.value }}
        </option>
      </select>
    </div>
    <div class="col-span-6 sm:col-span-3 mb-6 p-4 lg:p-0">
      <label for="work-type" class="block text-base font-semibold font-GTWalsheimPro text-[#090E11]/70">Company Size</label>
      <p></p>
      <select
        required
        v-model="details.employer.company_size"
        id="role-type"
        name="role-type"
        autocomplete="role-type"
        class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
      >
        <option disabled value="">Select an option</option>
        <option class="" v-for="(item, index) in companySize" :key="index" :value="item">
          {{ item }}
        </option>
      </select>
    </div>
    <div class="col-span-6 sm:col-span-3 mb-6 p-4 lg:p-0">
      <label for="work-type" class="block text-base font-semibold font-GTWalsheimPro text-[#090E11]/70">Total Funding</label>
      <select
        v-model="details.employer.funding"
        id="role-type"
        name="role-type"
        autocomplete="role-type"
        class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
      >
        <option class="" v-for="(item, index) in totalFunding" :key="index" :value="item">
          {{ item }}
        </option>
      </select>
    </div>
    <div class="flex flex-col">
      <h1 class="text-[#090E11] font-normal text-base">Upload Company Logo</h1>
      <span class="text-[#090E11]/70 font-normal text-sm">Minimum image size 72 x 72 pixels</span>
    </div>

    <div class="mt-3 p-4 rounded-md border-2 border-[#F7EDF5]">
      <div class="rounded-md border-2 border-dashed border-[#F7EDF5] flex flex-col py-5 items-center justify-around space-y-3">
        <span class="text-[#090E11]/70 font-normal text-sm">Select file to uplpad</span>
        <div class="">
          <img v-if="fileUrl" :src="fileUrl" class="mt- mb-4 h-40 w-40 object-cover mx-auto lg:mx-0" alt="companyLogo" />
          <label for="dropzone-file">
            <div class="py-3 px-4 space-x-2 flex items-center justify-between text-[#F0386A] border rounded border-[#F0386A] cursor-pointer">
              <CameraSvg />
              <span class="font-normal text-sm">Choose file</span>
              <ChevronDownSvg />
            </div>
            <input ref="selectedFile" id="dropzone-file" type="file" class="hidden" accept=".jpg,.jpeg,.png,.svg" @change="previewImage" />
          </label>
        </div>
        <span class="text-[#090E11]/70 font-normal text-sm">Supported format: JPG, PNG. File size limit is 10MB</span>
      </div>
    </div>
    <div class="w-full py-3 text-left mb-6">
      <button
        type="submit"
        :disabled="checkDisability"
        class="bg-brand-black sm:px-6 md:px-20 md:w-fit px-20 text-white py-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
      >
        {{ loading ? 'Loading...' : button_action }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import CameraSvg from '@/assets/icons/camera.svg?inline';
import ChevronDownSvg from '@/assets/icons/chevron-down.svg?inline';
import { errorMessage, alert } from '@/utils/helper';
import moment from 'moment';

const picked = ref(new Date());
const store = useStore();
const props = defineProps({
  task: { type: String, default: 'onboard' },
  details: { type: Object },
  logo_url: { type: String, default: '' },
});

const pageLoaded = ref(false);
const companyCategories = ref(null);
const selectedCategory = ref(null);
const years = ref([]);
const file = ref(null);
const fileUrl = ref(null);
const disabled = ref(false);
const loading = ref(false);
const companySize = ref(['1-5', '6-10', '11-50', '> 51']);
const totalFunding = ref([
  'below 100,000 USD',
  '$100k to $500k',
  '$500k to $1,00,000',
  '$1,000,000 to $5,000,000',
  '$5,000,000 to $10,000,000',
  '$10,000,000 and above',
]);

const checkDisability = computed(() => {
  return props.details.employer.company_category && props.details.employer.bio && fileUrl.value !== null
    ? (disabled.value = false)
    : (disabled.value = true);
});

const button_action = computed(() => (props.task == 'onboard' ? 'Continue' : 'Save'));

const userForm = computed(async () => await store.getters['global/getForm']);

const emit = defineEmits(['changeTab', 'updateAvatar']);

const userDetails = computed(async () => await store.getters['auth/userDetails']);

const setCategoryDetails = () => {
  if (selectedCategory.value) {
    props.details.employer.company_category = selectedCategory.value.key;
    props.details.employer.company_category_name = selectedCategory.value.value;
  }
};

const populateYears = () => {
  const currentYear = moment().year(); // Get the current year
  const startYear = 1900; // Start from 1900 (or any year you want)

  // Populate the years array from startYear to currentYear
  for (let year = currentYear; year >= startYear; year--) {
    years.value.push(year);
  }
};

const previewImage = (e) => {
  if (e?.target.files[0]) {
    let reader = new FileReader();
    reader.onload = (e) => {
      if (!e) return;
      fileUrl.value = e.target.result;
    };
    if (e.target.files[0]?.size > 5000000) {
      alert('file too big', 'error');
      return;
    }
    reader.readAsDataURL(e.target.files[0]);
    file.value = e.target.files[0];
  }
};

async function updateAvatar() {
  try {
    const data = new FormData();
    if (file.value) {
      data.append('logo', file.value);
      await store.dispatch('auth/updateEmployerAvatar', data);
    }
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}

async function handleUpdate() {
  try {
    disabled.value = true;
    loading.value = true;
    if (!file.value && !fileUrl.value) {
      alert('please select company logo', 'info');
      loading.value = false;
      return;
    }
    updateAvatar();
    if (props.task === 'onboard') {
      await store.commit('global/setForm', { ...userForm.value, ...props.details });
      emit('changeTab');
    } else {
      const user = await store.getters['auth/userDetails'];
      const formData = { ...user.employer, ...props.details };
      const { message } = await store.dispatch('auth/updateUserProfile', formData);
      alert(message, 'success');
      store.dispatch('auth/getUser', { forceReload: true });
    }
    disabled.value = false;
    loading.value = false;
  } catch (error) {
    disabled.value = false;
    loading.value = false;
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}

onMounted(async () => {
  populateYears();
  companyCategories.value = await store.dispatch('global/getCompanyCategory');
  fileUrl.value = props.about_logo;
  pageLoaded.value = true;
});
</script>

<style lang="scss" scoped>
.head {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #1b224b;
}

.tab-container {
  margin-top: 24px;
  margin-bottom: 40px;
}

#pv_id_1::v-deep {
  input {
    background-color: #fafafa !important;
    outline: none !important;
    border-radius: 0.375rem;
    border: none;
    margin-top: 0.25rem !important;
    width: 100% !important;
    display: block !important;
    padding: 1rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    &:focus {
      outline: none !important;
    }
  }
}
</style>

<template>
  <div class="">
    <p class="text-brand-black font-semibold text-base mb-6">Change Your Password</p>
    <form @submit.prevent="changePassword">
      <div class="col-span-6 sm:col-span-3 mb-6 lg:p-0">
        <label for="work-type" class="block text-base font-semibold text-[#090E11]/70">Current Password</label>

        <input
          v-model="v$.current_password.$model"
          type="password"
          id="input-group-1"
          class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
          placeholder="Enter password"
        />
        <div class="text-sm whitespace-nowrap text-red-500" v-for="(error, index) in v$.current_password.$errors" :key="index">
          {{ error.$message }}
        </div>
      </div>
      <div class="col-span-6 sm:col-span-3 mb-6 lg:p-0">
        <label for="work-type" class="block text-base font-semibold text-[#090E11]/70">New Password</label>

        <input
          v-model="v$.new_password.$model"
          type="password"
          id="new-password"
          class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
          placeholder="Enter new password"
        />
        <ul class="list-disc text-sm whitespace-nowrap text-red-500">
          <li v-for="error in v$.new_password.$errors" :key="error.$message">{{ error.$message }}</li>
        </ul>
      </div>
      <div class="col-span-6 sm:col-span-3 mb-6 lg:p-0">
        <label for="work-type" class="block text-base font-semibold text-[#090E11]/70">Confirm Password</label>

        <input
          v-model="v$.new_password_confirmation.$model"
          type="password"
          id="confirm_we_password"
          class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
          placeholder="confirm password"
        />
        <ul class="list-disc text-sm whitespace-nowrap text-red-500 mb-4">
          <li v-for="error in v$.new_password_confirmation.$errors" :key="error.$message">{{ error.$message }}</li>
        </ul>
      </div>
      <div class="py-3 mb-6">
        <button
          type="submit"
          :disabled="disabled"
          class="w-full bg-brand-black text-white p-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
        >
          {{ loading ? 'Loading...' : 'Change Password' }}
          <button-loader :loading="loading" />
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import { errorMessage, alert } from '@/utils/helper';
import ButtonLoader from '@/components/ButtonLoader';

const router = useRouter();
const store = useStore();
const loading = ref(false);
const disabled = ref(false);

const passwordDetails = ref({
  current_password: '',
  new_password: '',
  new_password_confirmation: '',
});

const passwordIsSame = (password) => {
  return password === passwordDetails.value.new_password;
};

const rules = {
  current_password: {
    required,
  },
  new_password: {
    required,
    upperCase: helpers.withMessage(
      () => `Include at least one uppercase letter`,
      (value) => /(?=.*[A-Z])/.test(value),
    ),
    minLength: minLength(8),
    lowerCase: helpers.withMessage(
      () => `Include at least one lowercase letter`,
      (value) => /(?=.*[a-z])/.test(value),
    ),
    number: helpers.withMessage(
      () => `Include at least one number`,
      (value) => /(?=.*?[0-9])/.test(value),
    ),
    specialCharacter: helpers.withMessage(
      () => `Include at least a special character`,
      (value) => /(?=.*?[#?!@$%^&*-])/.test(value),
    ),
  },
  new_password_confirmation: { required, sameAsPassword: helpers.withMessage('Password does not match', passwordIsSame) },
};

async function changePassword() {
  try {
    if (!v$.value.$anyDirty) return;
    loading.value = true;
    disabled.value = true;
    const { message } = await store.dispatch('auth/resetPassword', passwordDetails.value);
    alert(message, 'success', 4000);
    router.push('/dashboard');
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  } finally {
    loading.value = false;
    disabled.value = false;
  }
}

const v$ = useVuelidate(rules, passwordDetails);
</script>

<style></style>

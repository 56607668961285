<template>
  <div class="bg-white mb-16">
    <Tab @changeTab="changeTab" :numberOfTabs="tabs" :currentTab="tabNumber" borderColor="border-brand-button">
      <div class="rounded-lg w-full px-3" v-if="user.employer.can_edit_detail">
        <div v-if="tabNumber == 1" id="about" role="tabpanel" aria-labelledby="about-tab" class="grid mt-8">
          <about-company task="update" :details="user" />
        </div>
        <div v-if="tabNumber == 2" id="location" role="tabpanel" aria-labelledby="location-tab" class="grid mt-8">
          <company-location task="update" />
        </div>
        <div v-if="tabNumber == 3" id="social" role="tabpanel" aria-labelledby="social-tab" class="grid mt-8">
          <social-media task="update" />
        </div>
        <div v-if="tabNumber == 4" id="details" role="tabpanel" aria-labelledby="details-tab" class="grid mt-8">
          <employee-details task="update" />
        </div>
        <div v-if="tabNumber == 5" class="p-4 rounded-lg mt-4 grid lg:grid-cols-2" id="password" role="tabpanel"
          aria-labelledby="password-tab">
          <password-setting />
        </div>
      </div>
      <div class="rounded-lg w-full px-3" v-else>
        <div v-if="tabNumber == 1" id="details" role="tabpanel" aria-labelledby="details-tab" class="grid mt-8">
          <employee-details task="update" />
        </div>
        <div v-if="tabNumber == 2" class="p-4 rounded-lg mt-4 grid lg:grid-cols-2" id="password" role="tabpanel"
          aria-labelledby="password-tab">
          <password-setting />
        </div>
      </div>
    </Tab>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { alert } from '@/utils/helper';
import Tab from '@/components/Tab.vue';
import SocialMedia from '@/components/settings/SocialMedia.vue';
import AboutCompany from '@/components/settings/AboutCompany.vue';
import CompanyLocation from '@/components/settings/CompanyLocation.vue';
import EmployeeDetails from '@/components/settings/EmployeeDetails.vue';
import PasswordSetting from '@/components/settings/PasswordSetting.vue';

const route = useRoute();
const store = useStore();
const tabNumber = ref(1);
const user = ref({ employer: {} });

const tabs = ref([]);

const changeTab = (value) => {
  tabNumber.value = value;
};

const userDetails = computed(async () => await store.getters['auth/userDetails']);

onMounted(async() => {
  user.value = await store.getters['auth/userDetails'];
  if (user.value.employer.can_edit_detail) {
    tabs.value = [
      {
        name: 'About the Company',
      },
      {
        name: 'Location',
      },
      {
        name: 'Social Media',
      },
      {
        name: 'Your Details',
      },

      {
        name: 'Security',
      },
    ];
  } else {
    tabs.value = [
      {
        name: 'Your Details',
      },

      {
        name: 'Security',
      },
    ];
  }
  //check if to reset password
   if (route.query?.redirect === '/dashboard') {
    changeTab(tabs.value.length);
    alert('Kindly change your password to continue', 'info', 6000);
   }
})

</script>

<template>
  <form @submit.prevent="handleUpdate" class="mt-2">
    <div class="col-span-6 sm:col-span-3 mb-6 p-4 lg:p-0">
      <label for="work-type" class="block text-base font-semibold text-[#090E11]/70">About the Office</label>
      <textarea
        required
        id="about"
        v-model="details.about_office"
        rows="5"
        cols="33"
        placeholder="Start typing..."
        class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
      >
      </textarea>
      <div class="">
        <p class="text-right font-normal text-sm mt-1">Minimum of 80 characters</p>
      </div>
    </div>
    <div class="col-span-6 sm:col-span-3 mb-6 p-4 lg:p-0">
      <label for="work-type" class="block text-base font-semibold text-[#090E11]/70">Office Location</label>
      <input
        required
        v-model="details.location"
        type="text"
        id="input-group-1"
        class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
        placeholder="Type the location of the office"
      />
    </div>
    <div class="flex flex-col">
      <h1 class="text-[#090E11] font-normal text-base">Office or team photos <span class="text-red-600">(Optional)</span></h1>
      <span class="text-[#090E11]/70 font-normal text-sm">Minimum image size 72 x 72 pixels</span>
    </div>

    <div class="mt-3 p-4 rounded-md border-2 border-[#F7EDF5]">
      <div class="rounded-md border-2 border-dashed border-[#F7EDF5] flex flex-col py-5 items-center justify-around space-y-3">
        <span class="text-[#090E11]/70 font-normal text-sm">Select file to uplpad</span>
        <div class="">
          <img v-if="fileUrl" :src="fileUrl" class="mt- mb-4 h-40 w-40 object-cover mx-auto lg:mx-0" alt="companyBanner" />
          <label for="dropzone-file">
            <div class="py-3 px-4 space-x-2 flex items-center justify-between text-[#F0386A] border rounded border-[#F0386A] cursor-pointer">
              <CameraSvg />
              <span class="font-normal text-sm">Choose file</span>
              <ChevronDownSvg />
            </div>
            <input ref="selectedFile" id="dropzone-file" type="file" class="hidden" accept=".jpg,.jpeg,.png,.svg" @change="previewImage" />
          </label>
        </div>
        <span class="text-[#090E11]/70 font-normal text-sm">Supported format: JPG, PNG. File size limit is 10MB</span>
      </div>
    </div>
    <div class="w-full py-3 text-left mb-6">
      <button
        type="submit"
        class="bg-brand-black sm:px-6 md:px-20 md:w-fit px-20 text-white py-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
      >
        {{ loading ? 'Loading...' : button_action }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';
import CameraSvg from '@/assets/icons/camera.svg?inline';
import ChevronDownSvg from '@/assets/icons/chevron-down.svg?inline';

const store = useStore();
const props = defineProps({
  task: { type: String, default: 'onboard' },
});
const details = ref({
  about_office: '',
  location: '',
  banner: '',
});
const pageLoaded = ref(false);
const file = ref(null);
const fileUrl = ref(null);
const disabled = ref(false);
const loading = ref(false);
const banner = ref(null);

const button_action = computed(() => (props.task === 'onboard' ? 'Continue' : 'Save'));

const userForm = computed(async () => await store.getters['global/getForm']);

const emit = defineEmits(['changeTab']);

const userDetails = computed(async () => await store.getters['auth/userDetails']);

const checkDisability = computed(() => {
  return details.value.about_office && details.value.location !== '' && fileUrl.value !== null ? (disabled.value = false) : (disabled.value = true);
});

const previewImage = (e) => {
  if (e?.target?.files[0]) {
    let reader = new FileReader();
    reader.onload = (e) => {
      if (!e) return;
      fileUrl.value = e.target.result;
    };
    if (e?.target?.files[0]?.size > 5000000) {
      alert('file too big', 'error');
      return;
    }
    reader.readAsDataURL(e.target.files[0]);
    file.value = e.target.files[0];
  }
};

async function updateAvatar() {
  try {
    const data = new FormData();
    if (file.value) {
      data.append('banner', file.value);
      await store.dispatch('auth/updateCompanyBanner', data);
    }
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}

async function handleUpdate() {
  try {
    disabled.value = true;
    loading.value = true;

    updateAvatar();
    if (props.task == 'onboard') {
      await store.commit('global/setForm', { ...userForm.value, ...details.value });
      emit('changeTab');
    } else {
      const user = await store.getters['auth/userDetails'];
      const formData = { ...user.employer, ...details.value };
      const { message } = await store.dispatch('auth/updateUserProfile', formData);
      alert(message, 'success');
      store.dispatch('auth/getUser', { forceReload: true });
    }
    disabled.value = false;
    loading.value = false;
  } catch (error) {
    disabled.value = false;
    loading.value = false;
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}

onMounted(async () => {
  const user = await store.getters['auth/userDetails'];
  const formData = { ...user.employer, ...userForm.value };
  details.value = {
    about_office: formData.about_office,
    location: formData.location,
    banner: formData.banner,
  };
  banner.value = banner.value ? banner.value : formData.banner;
  pageLoaded.value = true;
});
</script>

<style scoped>
.head {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #1b224b;
}

.tab-container {
  margin-top: 24px;
  margin-bottom: 40px;
}
</style>

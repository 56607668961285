<template>
  <form @submit.prevent="handleUpdate" class="mt-6">
    <div class="col-span-6 sm:col-span-3 mb-8 p-4 lg:p-0">
      <label for="work-type" class="block text-base font-semibold text-[#090E11]/70">Links and Social Media</label>

      <input
        v-model="details.website"
        type="url"
        id="input-group-1"
        class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px] mb-4"
        placeholder="https://domain.ext"
      />
      <input
        v-model="details.linkedin"
        type="url"
        id="linkedin"
        class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px] mb-4"
        placeholder="https://www.linkedin.com/in/account"
      />
      <input
        v-model="details.facebook"
        type="url"
        id="facebook"
        class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px] mb-4"
        placeholder="https://www.facebook.com/company-name"
      />
      <input
        v-model="details.twitter"
        type="url"
        id="twitter"
        class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px] mb-4"
        placeholder="https://www.twitter.com/company-name"
      />
    </div>

    <div class="col-span-6 sm:col-span-3 mb-3 p-4 lg:p-0">
      <label for="work-type" class="block text-base font-semibold text-[#090E11]/70">Media Publications</label>
      <div v-for="(item, index) in details.publications" :key="index" class="flex">
        <input
          v-model="item.publication"
          type="url"
          class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px] mb-4"
          placeholder="'Any articles you would like to point out'"
        />
        <!--          Remove Svg Icon-->
        <svg
          v-show="details.publications.length > 1"
          @click.prevent="removeField(index, details.publications)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          class="ml-2 cursor-pointer"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            fill="#E91868"
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
          />
        </svg>
      </div>
    </div>

    <div class="px-4">
      <div class="flex w-fit cursor-pointer add-btn" @click.prevent="addField()">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M9.16602 15.8334C9.16602 16.2937 9.53911 16.6667 9.99935 16.6667C10.4596 16.6667 10.8327 16.2937 10.8327 15.8334V10.8334H15.8327C16.2929 10.8334 16.666 10.4603 16.666 10.0001C16.666 9.53985 16.2929 9.16675 15.8327 9.16675H10.8327L10.8327 4.16675C10.8327 3.70651 10.4596 3.33342 9.99935 3.33342C9.53911 3.33342 9.16602 3.70651 9.16602 4.16675L9.16602 9.16675L4.16602 9.16675C3.70578 9.16675 3.33268 9.53984 3.33268 10.0001C3.33268 10.4603 3.70578 10.8334 4.16602 10.8334L9.16602 10.8334V15.8334Z"
            fill="#E91868"
          />
        </svg>
        <h3 class="text-red-500 add ml-1 text-base font-medium leading-5">Add</h3>
      </div>
    </div>

    <div class="w-full py-3 px-4 text-left mb-6">
      <button
        :disabled="checkDisability"
        type="submit"
        class="bg-brand-black sm:px-6 md:px-20 md:w-fit px-20 text-white py-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
      >
        {{ loading ? 'Loading...' : button_action }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';

const store = useStore();
const details = ref({
  website: '',
  linkedin: '',
  facebook: '',
  twitter: '',
  publications: [{ publication: '' }],
});
const pageLoaded = ref(false);
const disabled = ref(false);
const loading = ref(false);

const props = defineProps({
  task: { type: String, default: 'onboard' },
});
const userDetails = computed(async () => await store.getters['auth/userDetails']);
const button_action = computed(() => (props.task === 'onboard' ? 'Continue' : 'Save'));
const userForm = computed(async () => await store.getters['global/getForm']);

const emit = defineEmits(['changeTab']);

const checkDisability = computed(() => {
  return details.value.website || details.value.linkedin !== '' ? (disabled.value = false) : (disabled.value = true);
});

const addField = () => {
  try {
    details.value.publications.push({
      publication: '',
      placeholder: 'Any articles you would like to point out',
    });
  } catch (error) {
    console.log(error);
  }
};

const removeField = (index, fieldName) => {
  fieldName.splice(index, 1);
};

async function handleUpdate() {
  try {
    disabled.value = true;
    loading.value = true;

    if (props.task === 'onboard') {
      await store.commit('global/setForm', { ...userForm.value, ...details.value });
      emit('changeTab');
    } else {
      const user = await store.getters['auth/userDetails'];
      const formData = { ...user.employer, ...details.value };
      const { message } = await store.dispatch('auth/updateUserProfile', formData);
      alert(message, 'success');
      store.dispatch('auth/getUser', { forceReload: true });
    }
  } catch (error) {
    disabled.value = false;
    loading.value = false;
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}

onMounted(async () => {
  const user = await store.getters['auth/userDetails'];
  const formData = { ...user.employer, ...userForm.value };
  details.value = {
    website: formData.website,
    linkedin: formData.linkedin || formData.social_links?.linkedin,
    facebook: formData.facebook || formData.social_links?.facebook,
    twitter: formData.twitter || formData.social_links?.twitter,
    publications: formData.publications || [],
  };
  pageLoaded.value = true;
});
</script>

<style scoped>
.head {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #1b224b;
}

.tab-container {
  margin-top: 24px;
  margin-bottom: 40px;
}

input[type='checkbox'] {
  color: rgba(0, 0, 0, 0.2);
  margin-right: 14px;
}

.add-btn {
  width: fit-content;
}

.add {
  color: #e91868;
}
</style>
